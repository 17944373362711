import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as Signin3svg } from '../assets/Signin3svg.svg';
import { ReactComponent as Profilesvg } from '../assets/Profilesvg.svg';
import Slider from '../Slider';
import Logo from '../Shared/Logo';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { setCookie } from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app';
import { AES, enc } from 'crypto-js';

const AddLogo = () => {

    document.title = 'Business Setup - Taojaa';

    const user = JSON.parse(localStorage.getItem('user'));

    const [loading, setLoading] = useState(false);
    const [done, setDone] = useState(false);

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const imagePath = files.map(file => (
        <div className='imagePath' key={file.name}>
            <div>
                <img
                    className='tj-upload-img'
                    src={file.preview}
                    alt=''
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const handleSubmit = () => {

        setLoading(true)

        let token = AES.decrypt(localStorage.getItem('token'), process.env.REACT_APP_CRYPTO_SECRET_KEY).toString(enc.Utf8)
        token = token.split(':')[1];

        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        var form = new FormData();
        form.append('user_id', user.id);
        form.append('file', files[0]);

        axios.post('/onboarding/add-logo', form, config).then(response => {
            if (response.data.success) {
                setDone(true)
            }
        }).catch(error => {
            setLoading(false)
            if (error.response) {
                messageHandler("error", error.response.data.message);
            } else {
                messageHandler("error", 'Unable to process request. Please try again');
            }
        })
    }

    if (!user) {
        return <Navigate to={'/auth/create-account'} />
    }

    if (done) {
        setCookie(process.env.REACT_APP_COOKIE_NAME, user.id, 100, 'taojaa.com');
        return <Navigate to={'/auth/success'} />
    }

    return (
        <div className='col-md-12'>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-4'>
                        <div className='tj-box'>
                            <h1 className='mb-1'>Add a logo for your business</h1>
                            <h2 className='mb-5'>Setup your business store account</h2>
                            <div className='d-flex flex-column justify-content-center align-items-center gap-2'>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <Profilesvg className='mt-4' style={{ height: '8rem' }} />
                                    <div className='imageContainer'>{imagePath}</div>
                                </div>

                                <button className={`tj-box1 mt-4 mb-1 ${loading ? 'button-disabled' : ''}`} disabled={loading} onClick={handleSubmit}>
                                    {loading ? <lord-icon
                                        src="https://cdn.lordicon.com/ymrqtsej.json"
                                        trigger="loop"
                                        colors="primary:#ffffff"
                                        style={{ width: "50px", height: "50px" }}>
                                    </lord-icon> : 'Proceed'}
                                </button>
                                <button className='tj-box2 mt-4' onClick={() => setDone(true)}>Skip</button>
                            </div>
                            <Slider selected={3} marginTop={6} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Signin3svg className='tj-right' />
                </div>
            </div >
        </div>
    )
}

export default AddLogo;