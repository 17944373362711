import React, { useEffect, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { ReactComponent as Signin2svg } from '../assets/Signin2svg.svg';
import ValidateSubmit from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app'
import Logo from '../Shared/Logo';
import Slider from '../Slider';
import axios from 'axios';
import { AES, enc } from 'crypto-js';

const BusinessStore = () => {

    document.title = 'Business Setup - Taojaa';

    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [category, setCategory] = useState([])

    const user = JSON.parse(localStorage.getItem('user'));
    const [store, setStore] = useState({
        user_id: user ? user.id : null,
        store_name: '',
        description: '',
        category: '',
        means_of_knowing: '',
        store_type: "live"
    });

    const channels = [
        { name: 'Twitter' },
        { name: 'Instagram' },
        { name: 'Facebook' },
        { name: 'Google Search' },
        { name: 'From a Friend' },
        { name: 'WhatsApp' },
        { name: 'Email Newsletter' },
        { name: 'Others' },
    ];

    useEffect(() => {
        fetchCategory()
    }, [])

    const fetchCategory = async () => {
        try {
            let response = await axios.get(`${process.env.REACT_APP_STORE_ENDPOINT}/store-categories`)
            if (response.data.success) {
                const categories = response.data.categories
                setCategory(categories)

            }
        } catch (error) {

        }
    }

    const handleInput = (e) => {

        var field = e.target.name;
        var value = e.target.value;

        setStore({ ...store, [field]: value });
    }

    const handleSubmit = e => {
        e.preventDefault()

        if (ValidateSubmit(false, true)) {

            setLoading(true);

            let token = AES.decrypt(localStorage.getItem('token'), process.env.REACT_APP_CRYPTO_SECRET_KEY).toString(enc.Utf8)
            token = token.split(':')[1];

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            axios.get(`/onboarding/check-store-name?store_name=${store.store_name}`, config).then(response => {
                if (response.data.success) {
                    axios.post('/onboarding/create-store', store, config).then(response => {
                        if (response.data.success) {
                            messageHandler('success', response.data.message);
                            setRegistered(true)
                        } else {
                            messageHandler('error', response.data.message)
                        }

                    }).catch(error => {
                        setLoading(false)
                        if (error.response) {
                            messageHandler("error", error.response.data.message);
                        } else {
                            messageHandler("error", 'Unable to process request. Please try again');
                        }
                    })
                }
            }).catch(error => {
                setLoading(false)
                if (error.response) {
                    messageHandler("error", error.response.data.message);
                } else {
                    messageHandler("error", 'Something went wrong. Please try again');
                }
            })
        }
    }


    if (!user) {
        return <Navigate to={'/auth/create-account'} />
    }

    if (registered) {
        return <Navigate to={'/auth/add-logo'} />
    }

    return (

        <div className='col-md-12'>
            <div className='tj-form-response'>
                <p id='tj-resp-Message'></p>
            </div>
            <div className='row tj-everything'>
                <div className='col-md-6'>
                    <Logo />
                    <div className='d-flex justify-content-center mt-4'>
                        <div className='tj-box'>
                            <h1 className='mb-1'>Setup your business</h1>
                            <h2>Fill in your business details</h2>
                            <form>
                                <div className="tj-form mb-3 position-relative">
                                    <div className='form-group mb-2'>
                                        <label for="name" className="form-label">Business Name</label>
                                        <input
                                            name='store_name'
                                            type={"text"}
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#storenameerror'
                                            validationmessage='Business name cannot be empty!!'
                                            className="form-control"
                                            id="name"
                                            placeholder='Designer Store' />
                                    </div>

                                    <div className='form-group mb-2'>
                                        <label for="category" className="form-label">Category</label>
                                        <select
                                            name='category'
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#categoryerror'
                                            validationmessage='Category cannot be empty!!'
                                            id="category"
                                            className="form-select">
                                            <option value="">Select...</option>
                                            {category.map((category, index) => {
                                                return <option value={category.id} key={index} >{category.name}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className='form-group mb-2'>
                                        <label for="means_of_knowing" className="form-label">How did you hear about Taojaa?</label>
                                        <select
                                            name='means_of_knowing'
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#meanserror'
                                            validationmessage='Kindly select an option!'
                                            id="means_of_knowing"
                                            className="form-select">
                                            <option value="">Select an option</option>
                                            {channels.map((channel, index) => {
                                                return <option value={channel.name} key={index} >{channel.name}</option>
                                            })}
                                        </select>
                                    </div>

                                    <div className='form-group mb-2'>

                                        <label for="description" className="form-label">Business Description</label>
                                        <textarea
                                            name='description'
                                            onChange={e => handleInput(e)}
                                            validatefield='true'
                                            validationoutput='#descriptionerror'
                                            validationmessage='Description cannot be empty!!'
                                            style={{ height: '10rem', resize: 'none' }}
                                            type={"text"}
                                            className="form-control mb-3 p-1 tj-description"
                                            id="description"
                                            placeholder='E.g The name of my business is designer store and we provide catering services to business'
                                            maxlength="500"
                                        />
                                    </div>
                                </div>
                            </form>
                            <button className={`tj-box1 mt-4 mb-3 ${loading ? 'button-disabled' : ''}`} disabled={loading} onClick={handleSubmit}>
                                {loading ? <lord-icon
                                    src="https://cdn.lordicon.com/ymrqtsej.json"
                                    trigger="loop"
                                    colors="primary:#ffffff"
                                    style={{ width: "50px", height: "50px" }}>
                                </lord-icon> : 'Proceed'}
                            </button>
                            <div className='d-flex justify-content-center gap-1 mt-2 tj-acct'>
                                <span>Already have an account?</span> <Link to={"/auth/login"} className='text-primary'>Sign in</Link>
                            </div>
                            <Slider selected={2} marginTop={1.8} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6 desktop'>
                    <Signin2svg className='tj-right' />
                </div>
            </div >
        </div>
    )
}

export default BusinessStore;