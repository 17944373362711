import React, { useState } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as Loginn } from '../assets/Loginn.svg';
import Logo from '../Shared/Logo';
import { FcGoogle } from 'react-icons/fc';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import axios from 'axios';
import ValidateSubmit, { setCookie, deleteCookie, getCookie, randomString } from 'wearslot-dev-utils';
import { messageHandler } from '../../bundles/app'
import { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { getContinueLink } from './Success';
import { AES } from 'crypto-js';


const Login = () => {
  document.title = "Login - Taojaa";

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
    sign_up_method: 1,
  });
  const [completeSetup, setCompleteSetup] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => getUserProfile(codeResponse),
  });

  useEffect(() => {
    if (searchParams.get("logout")) {
      return deleteCookie(
        process.env.REACT_APP_COOKIE_NAME,
        process.env.REACT_APP_DOMAIN
      );
    }

    if (getCookie(process.env.REACT_APP_COOKIE_NAME)) {
      const next = getCookie("next");
      deleteCookie("next");
      return window.location = next || getContinueLink();
    }
  }, [searchParams]);

  async function getUserProfile(codeResponse) {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        }
      );

      if (response.data) {
        var userData = { ...user };
        userData.email = response.data.email;
        userData.password = "";
        userData.sign_up_method = 2;

        loginUserAccount(userData);
      }
    } catch (error) {
      return messageHandler("error", "Unable to get auth user profile.");
    }
  }

  function loginUserAccount(user) {
    axios
      .post("/account/login", user)
      .then((response) => {
        if (response.data.success) {
          messageHandler("success", response.data.message);

          const randomString1 = randomString(56);
          const randomString2 = randomString(56);

          const token = AES.encrypt(`${randomString1}:${response.data.token}:${randomString2}`, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString();

          if (!response.data.setup_completed) {
            localStorage.setItem("details", JSON.stringify(user));
            localStorage.setItem("user", JSON.stringify(response.data.user));

            localStorage.setItem("token", token);
            return setCompleteSetup(true);
          }

          setCookie(process.env.REACT_APP_COOKIE_NAME, token, 100, process.env.REACT_APP_DOMAIN);
          var onboarding = false;
          if (response.data.first_login) {
            onboarding = true;
          }

          const next = getCookie("next");
          deleteCookie("next");

          window.location = next || getContinueLink(onboarding);
        }

      }).catch(error => {
        setLoading(false)
        if (error.response) {
          return messageHandler("error", error.response.data.message);
        }
        return messageHandler("error", "Something went wrong..");
      })
  }

  const handleInput = (e) => {
    var field = e.target.name;
    var value = e.target.value;

    setUser({ ...user, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (ValidateSubmit(false, true)) {
      setLoading(true);
      loginUserAccount(user);
    }
  };

  if (completeSetup) {
    return <Navigate to={"/auth/store-details"} />;
  }

  return (
    <div className="col-md-12 bg-white">
      <div className="tj-form-response">
        <p id="tj-resp-Message"></p>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="logo-box mt-4 px-4">
            <Logo />
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div className="tj-box">
              <div className="text-center-mobile">
                <h1 className="mb-1">Welcome back</h1>
                <h2>Sign in to continue</h2>
              </div>
              <button
                className="tj-google d-flex align-items-center justify-content-center"
                onClick={() => googleLogin()}
              >
                <FcGoogle />
                <span>Sign in with Google</span>
              </button>
              <div className="tj-line d-flex align-items-center mt-3 gap-1">
                <span></span>
                <div>or</div>
                <span></span>
              </div>
              <form>
                <div className="tj-form mb-3 position-relative">
                  <label htmlFor="Email" className="form-label">
                    Your Email
                  </label>
                  <input
                    type={"email"}
                    value={user.email}
                    name="email"
                    onChange={(e) => handleInput(e)}
                    validatefield="true"
                    validationoutput="#emailerror"
                    validationmessage="Email cannot be empty!!"
                    className="form-control mb-3"
                    id="Email1"
                    aria-describedby="emailHelp"
                    placeholder="Enter your email address"
                  />

                  <label htmlFor="Password" className="form-label">
                    Password
                  </label>
                  <input
                    type={visibility ? "text" : "password"}
                    value={user.password}
                    name="password"
                    onChange={(e) => handleInput(e)}
                    validatefield="true"
                    validationoutput="#passwordError"
                    validationmessage="Password cannot be empty!*"
                    className="form-control"
                    id="Password"
                    placeholder="Enter your password"
                  />
                  {visibility ? (
                    <AiOutlineEye
                      className="tj-icon1"
                      onClick={() => setVisibility(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="tj-icon1"
                      onClick={() => setVisibility(true)}
                    />
                  )}
                </div>

                <button
                  className={`tj-box1 mt-4 ${loading ? "button-disabled" : ""}`}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <lord-icon
                      src="https://cdn.lordicon.com/ymrqtsej.json"
                      trigger="loop"
                      colors="primary:#ffffff"
                      style={{ width: "50px", height: "50px" }}
                    ></lord-icon>
                  ) : (
                    "Proceed"
                  )}
                </button>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="mt-3 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="Check1">
                      Remember me
                    </label>
                  </div>
                  <Link to={"/auth/forgot-password"} id="tj-forgottenpassword">
                    Forget Password
                  </Link>
                </div>
                <div className="d-flex justify-content-center gap-1 mt-2 tj-acct">
                  <span>Don't have an account?</span>
                  <Link
                    to={"/auth/create-account"}
                    style={{ color: "#8A50FC", textDecoration: "none" }}
                  >
                    Sign up
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 desktop">
          <Loginn className="tj-right" />
        </div>
      </div>
    </div>
  );
};

export default Login;
